import { Routes } from '@constants/routes';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const PageNotFound = () => {
  const router = useRouter();

  useEffect(() => {
    router.push(Routes.index);
  }, []);

  return null;
};

export default PageNotFound;
